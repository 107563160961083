<template>
  <div id="user-fields">
    <div class="row">
      <div class="col-12">
        <div class="form-group position-relative">
          <input name="cardholder"
            id="cardholder"
            type="text"
            class="form-control"
            v-bind:class="{ 'is-invalid': $v.form.card.cardholder.$error, }"
            placeholder="Cardholder's name :"
            v-model.trim="$v.form.card.cardholder.$model"/>
          <div class="invalid-feedback" v-bind:class="{ 'd-block': $v.form.card.cardholder.$error, }">
            <span v-if="!$v.form.card.cardholder.required">This field is required</span>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="form-group position-relative">
          <input name="Card number"
            id="number"
            type="number"
            class="form-control"
            v-bind:class="{ 'is-invalid': $v.form.card.number.$error, }"
            placeholder="Card number :"
            v-model.number.trim="$v.form.card.number.$model"/>
          <div class="invalid-feedback" v-bind:class="{ 'd-block': $v.form.card.number.$error, }">
            <span v-if="!$v.form.card.number.required">This field is required</span>
            <span v-if="!$v.form.card.number.numeric">This is not a valid credit card number</span>
          </div>
        </div>
      </div>
      <!--end col-->
      <div class="col-8">
        <div class="form-group position-relative">
          <input name="date"
            id="date"
            type="text"
            class="form-control"
            v-bind:class="{ 'is-invalid': $v.form.card.date.$error, }"
            placeholder="MM/YYYY"
            v-model.trim="$v.form.card.date.$model"/>
          <div class="invalid-feedback" v-bind:class="{ 'd-block': $v.form.card.date.$error, }">
            <span v-if="!$v.form.card.date.required">This field is required</span>
          </div>
        </div>
      </div>
      <!--end col-->
      <div class="col-4 pl-0">
        <div class="form-group position-relative">
          <input name="cvc"
            id="cvc"
            type="number"
            class="form-control"
            v-bind:class="{ 'is-invalid': $v.form.card.cvc.$error, }"
            placeholder="CVC"
            v-model.number.trim="$v.form.card.cvc.$model"/>
          <div class="invalid-feedback" v-bind:class="{ 'd-block': $v.form.card.cvc.$error, }">
            <span v-if="!$v.form.card.cvc.required">This field is required</span>
            <span v-if="!$v.form.card.cvc.numeric">This is not a valid CVC</span>
          </div>
        </div>
      </div>
    </div>
    <!--end row-->
  </div>
</template>

<script>
  import countries from "@/utils/countries";
  export default {
    props: {
      $v: Object,
    },
    data() {
      return {
        countries,
      };
    },
  };
</script>